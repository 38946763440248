<template>
  <a-select
    :mode="mode"
    v-model:value="valueState"
    placeholder="Pilih Nama UOM"
    allow-clear
    :show-arrow="true"
    not-found-content="Tidak ditemukan"
    option-filter-prop="label"
    option-label-prop="label"
    @search="onSearch"
    @select="() => (findText = null)"
    show-search
    v-bind="$attrs"
  >
    <a-select-option v-for="d in items" :key="d.id" :label="LabelText(d)">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="highlight(LabelText(d))"></span>
      <!--eslint-enable-->
    </a-select-option>
  </a-select>
</template>

<script>
import { onMounted, ref, toRefs, defineComponent, watch } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'

export default {
  props: {
    value: { type: [Array, Number, Object, String], default: null },
    mode: {
      default: 'multiple',
      type: String,
    },
    program: {
      default: () => '',
      type: String,
    },
    tipeUom: {
      default: () => '',
      type: String,
    },
    labels: {
      default: () => ['fullname'],
      type: Array,
    },
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const items = ref([])
    const findText = ref(null)
    const program = toRefs(props).program
    const tipeUom = toRefs(props).tipeUom

    const fetchData = (program = null, tipe_uom = null) => {
      apiClient
      .get('/api/filter/loyalty-uom', { params: { program, tipe_uom } })
      .then(response => {
        const sorted = response.data
        items.value = sorted.sort((a, b) => (a.fullname.toLowerCase() > b.fullname.toLowerCase() ? 1 : -1))
      })
    }

    const LabelText = (data) => {
      let items = []

      props.labels.forEach(item => {
        items.push(data[item])
      })

      return items.join(' - ')
    }

    onMounted(() => {
      fetchData(program.value, tipeUom.value)
    })

    watch(program, (after, before) => {
      emit('update:value', [])
      let val = null
      if (tipeUom.value !== '' || tipeUom.value) val = after
      fetchData(program.value, val)
    })

    watch(tipeUom, (after, before) => {
      emit('update:value', [])
      let val = null
      if (program.value !== '' || program.value) val = after
      fetchData(val, tipeUom.value)
    })

    const onSearch = value => {
      findText.value = value
    }

    const highlight = value => {
      return value.replace(new RegExp(findText.value, 'gi'), match => {
        return `<span style="background-color: yellow;">${match}</span>`
      })
    }
    return { items, valueState: useVModel(props, 'value'), findText, highlight, onSearch, LabelText }
  },
}
</script>

<style></style>
