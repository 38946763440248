<template>
  <a-modal
    :visible="visible"
    :title="model.readOnly ? 'Lihat Reward' : form.id ? 'Edit Reward' : 'Tambah Reward'"
    @ok="handleOk"
    @cancel="handleCancel"
    width="1000px"
    :destroy-on-close="true"
  >
    <template #footer>
      <a-button key="back" :hidden="model.readOnly" @click="handleCancel">Cancel</a-button>
      <a-button
        key="submit"
        type="primary"
        :loading="loading"
        :hidden="model.readOnly"
        @click="handleOk"
        >Submit</a-button
      >
    </template>
    <a-form
      ref="formRef"
      class="myform"
      :model="form"
      :rules="rules"
      :wrapper-col="{ span: 14 }"
      :scroll-to-first-error="true"
    >
      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Kode"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            required
            has-feedback
            name="code"
          >
            <a-input
              placeholder="Kode Reward"
              width="100%"
              v-model:value="code"
              :disabled="model.readOnly"
              required
              maxlength="50"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Title"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            required
            has-feedback
            name="title"
          >
            <!-- <a-input
              placeholder="Title Reward"
              v-model:value="title"
              :disabled="model.readOnly"
              required
              maxlength="50"
            /> -->
            <a-auto-complete
              v-model:value="title"
              placeholder="Title Reward"
              :options="suggestTitle"
              :filter-option="filterOption"
              :disabled="model.readOnly"
              required
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Deskripsi"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            name="description"
          >
            <a-input
              placeholder="Deskripsi Reward"
              v-model:value="description"
              :disabled="model.readOnly"
              maxlength="100"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Gambar"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            required
            has-feedback
            name="image"
          >
            <template v-if="!model.readOnly">
              <a-input
                required
                v-if="!image || image === 'null'"
                type="file"
                accept="image/png, image/gif, image/jpeg"
                :key="fileImageKey"
                @change="e => onFileChange(e, 'image')"
              >
              <template #suffix>
                <a-tooltip title="Recomended: Dimension:320 x 180, File Size:2048Kb, File Type:jpg/png/gif/jpeg">
                  <span role="img" aria-label="question-circle" type="primary" class="anticon anticon-question-circle"><svg focusable="false" class="" data-icon="question-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true" viewBox="64 64 896 896"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z"></path></svg></span>
                </a-tooltip>
              </template>
              </a-input>
              <a-button
                v-else
                @click="
                  () => {
                    image = null
                    url_image = null
                  }
                "
                >Hapus gambar reward</a-button
              >
            </template>
            <img
              v-if="image !== 'null'"
              style="display: block;
              max-width:150px;
              width: auto;
              height: auto;"
              :src="typeof image === 'string' ? image : url_image"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Masa Berlaku"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            required
            has-feedback
            name="valid_until"
            >
            <a-date-picker
              style="width: 100%"
              v-model:value="valid_until"
              format="DD MMMM YYYY"
              placeholder="Valid Date"
              :disabled="model.readOnly"
              @change="changeMasaBerlaku"
              required
            />
            <a-date-picker style="display: none;" v-model:value="valid_until"/>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Tipe"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            required
            has-feedback
            name="type"
          >
            <a-auto-complete
              v-model:value="type"
              placeholder="Tipe Reward"
              :options="suggestType"
              :filter-option="filterOption"
              :disabled="model.readOnly"
              required
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Kategori"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            required
            has-feedback
            name="category"
          >
            <a-auto-complete
              v-model:value="category"
              placeholder="Kategori Reward"
              width="100%"
              :options="suggestCategory"
              :filter-option="filterOption"
              :disabled="model.readOnly"
              required
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Area"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            required
            has-feedback
            name="area"
          >
            <a-auto-complete
              v-model:value="area"
              placeholder="Area Reward"
              width="100%"
              :options="suggestArea"
              :filter-option="filterOption"
              :disabled="model.readOnly"
              required
            />
            <span class="text-danger" v-if="String(area).length > 50 && area != null">Maksimal 50 Karakter</span>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Redeem Point"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            name="redeem_point"
          >
            <a-input
              placeholder="Redeem Point"
              width="100%"
              type="number"
              v-model:value="redeem_point"
              :disabled="model.readOnly"
              min="0"
              max="8"
            />
            <span class="text-danger" v-if="String(redeem_point).length > 8">Maksimal 8 Karakter</span>
            <span class="text-danger" v-show="isNegativePoint">Point tidak boleh negatif</span>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Redeem Access"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            name="redeem_access">
            <ARadioGroup v-model:value="redeem_access">
              <ARadio :value="1">True</ARadio>
              <ARadio :value="0">False</ARadio>
            </ARadioGroup>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="12">
          <a-form-item
            label="Jumlah (qty)"
            label-align="left"
            :label-col="{ sm: { span: 8 } }"
            :wrapper-col="{ sm: { span: 24 - 8 } }"
            name="max_qty"
          >
            <a-input
              type="number"
              width="100%"
              min="0"
              v-model:value="max_qty"
              :disabled="model.readOnly || unlimit_max_qty"
              max="5"
            />
            <span class="text-danger" v-if="String(max_qty).length > 7">Maksimal 7 Karakter</span>
            <span class="text-danger" v-show="isNegativeMaxQty">Jumlah tidak boleh negatif</span>
          </a-form-item>
        </a-col>
        <a-col :sm="12">
          <a-checkbox
            class="mr-2 mt-2"
            v-model:checked="unlimit_max_qty"
            :disabled="model.readOnly">Unlimited</a-checkbox>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="12">
          <a-form-item
            label="Kuota Per Store"
            label-align="left"
            :label-col="{ sm: { span: 8 } }"
            :wrapper-col="{ sm: { span: 24 - 8 } }"
            name="quota_store"
          >
            <a-input
              type="number"
              width="100%"
              min="0"
              v-model:value="quota_store"
              :disabled="model.readOnly || unlimit_quota_store"
              max="3"
            />
            <span class="text-danger" v-if="String(quota_store).length > 7 && quota_store != null">Maksimal 7 Karakter</span>
            <span class="text-danger" v-show="isNegativeQuotaStore">Jumlah tidak boleh negatif</span>
          </a-form-item>
        </a-col>
        <a-col :sm="12">
          <a-checkbox
            class="mr-2 mt-2"
            v-model:checked="unlimit_quota_store"
            :disabled="model.readOnly">Unlimited</a-checkbox>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="UOM"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            name="uom_id"
          >
            <filter-loyalty-uom
              type="text"
              style="width: 100%"
              v-model:value="uom_id"
              v-model:tipe-uom="type"
              :labels="['code', 'program_code']"
              :disabled="model.readOnly"
              :mode="null"
              required
            ></filter-loyalty-uom>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :md="12" :sm="24">
          <a-form-item
            label="Tanggal Mulai Redeem"
            label-align="left"
            :label-col="{ sm: { span: 8 } }"
            :wrapper-col="{ sm: { span: 24 - 8 } }"
            required
            has-feedback
            name="start_redeem_date"
          >
            <a-date-picker
              style="width: 100%"
              v-model:value="start_redeem_date"
              format="DD MMMM YYYY"
              placeholder="Start Date"
              :disabled-date="disabledStartDate"
              :disabled="model.readOnly"
              @change="changeStartDate"
              required
            />
            <a-date-picker style="display: none;" v-model:value="start_redeem_date"/>
          </a-form-item>
        </a-col>
        <a-col :md="12" :sm="24">
          <a-form-item
            label="Berakhir Redeem"
            label-align="left"
            :label-col="{ sm: { span: 8 } }"
            :wrapper-col="{ sm: { span: 24 - 8 } }"
            required
            has-feedback
            name="end_redeem_date"
          >
            <a-date-picker
              style="width: 100%"
              v-model:value="end_redeem_date"
              format="DD MMMM YYYY"
              placeholder="End Date"
              :disabled-date="disabledEndDate"
              :disabled="model.readOnly"
              required
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
  </a-modal>
</template>
<script>
import { defineComponent, reactive, toRef, toRefs, ref, computed, onBeforeMount, onMounted, watch } from 'vue'
import apiClient from '@/services/axios'
import { message } from 'ant-design-vue'
import { serialize } from 'object-to-formdata'
import FilterLoyaltyUom from '@/components/filter/FilterLoyaltyUom'
import rules from './rules'
import moment from 'moment'

export default defineComponent({
  components: {
    FilterLoyaltyUom,
  },
  props: {
    visible: [Boolean],
    model: {
      type: [Object],
      default: () => ({
        id: null,
        code: null,
        title: null,
        description: null,
        image: null,
        valid_until: null,
        customer_id: null,
        type: null,
        active: null,
        category: null,
        redeem_point: null,
        redeem_access: null,
        area: null,
        uom_id: null,
        max_qty: null,
        quota_store: null,
        start_redeem_date: null,
        end_redeem_date: null,
        access_clients_created_by: null,
        access_clients_updated_by: null,
        unlimit_max_qty: false,
        unlimit_quota_store: false,
        created_at: null,
        created_by: null,
        updated_at: null,
        updated_by: null,
        is_deleted: false,
        record: {},
      }),
    },
  },
  emits: ['handleOk', 'update:visible'],
  setup(props, { emit }) {
    const form = reactive(Object.assign({}, props.model.record))
    const formRef = ref()
    const errorMessage = ref(null)
    const image = toRef(form, 'image')
    const data = ref()
    const suggestTitle = reactive([])
    const suggestType = reactive([])
    const suggestCategory = reactive([])
    const suggestArea = reactive([])
    const isNegativeMaxQty = ref(false)
    const isNegativePoint = ref(false)
    const isNegativeQuotaStore = ref(false)
    const isDateValidChange = ref(false)

    const fileImageKey = ref(0)

    const dbsBerlaku = computed(() => form.valid_until)
    const dbsStart = computed(() => form.start_redeem_date)
    const dbsEnd = computed(() => form.end_redeem_date)
    const disabledStartDate = start_date => {
      if (!start_date || !moment(dbsBerlaku.value)) {
        return false
      }
      if (form.id) {
        if (isDateValidChange.value == true) {
          return moment(dbsBerlaku.value, "DD-MM-YYYY").valueOf() < start_date.valueOf()
        } else {
          return moment(dbsBerlaku.value).valueOf() < start_date.valueOf()
        }
      } else {
        return moment(dbsBerlaku.value, "DD-MM-YYYY").add(1, 'days').valueOf() < start_date.valueOf()
      }
    }

    const disabledEndDate = end_date => {
      if (!end_date || !moment(dbsBerlaku.value)) {
        return false
      }
      if (form.id) {
        if (isDateValidChange.value == true) {
          return moment(dbsStart.value, "DD-MM-YYYY").valueOf() > end_date.valueOf() || moment(dbsBerlaku.value, "DD-MM-YYYY").add(1, 'days').valueOf() < end_date.valueOf()
        } else {
          return moment(dbsStart.value).valueOf() > end_date.valueOf() || moment(dbsBerlaku.value).valueOf() < end_date.valueOf()
        }
      } else {
        return moment(dbsStart.value, "DD-MM-YYYY").valueOf() > end_date.valueOf() || moment(dbsBerlaku.value, "DD-MM-YYYY").add(1, 'days').valueOf() < end_date.valueOf()
      }
    }

    const vDate = computed(() => moment(dbsBerlaku.value).valueOf())
    const sDate = computed(() => moment(dbsStart.value).valueOf())
    const eDate = computed(() => moment(dbsEnd.value).valueOf())

    const changeMasaBerlaku = () => {
      isDateValidChange.value = true
      sDate.value > vDate.value ? form.start_redeem_date = null : form.start_redeem_date
      eDate.value > vDate.value ? form.end_redeem_date = null : form.end_redeem_date
    }

    const changeStartDate = () => {
      eDate.value < sDate.value ? form.end_redeem_date = null : form.end_redeem_date
    }

    const fotos = reactive({
      url_image: null,
    })

    const fetchData = () => {
      apiClient
        .get('/api/filter/loyalty-reward')
        .then(response => {
          const items = response.data
          data.value = items

          suggestTitle.splice(0)
          suggestType.splice(0)
          suggestCategory.splice(0)
          suggestArea.splice(0)

          data.value.forEach((value, index) => {
            // Suggest Title
            if (value.title != null) {
              if ( suggestTitle.length <= 0) { suggestTitle.push({value : value.title.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase())}) }
              else {
                var isExist = true
                var countExist = 0
                suggestTitle.forEach((val, i) => {
                  if (value.title.toLowerCase().trim() !== val.value.toLowerCase().trim()) { isExist = false, countExist += 1 }
                  else { isExist = true }
                });
                if (isExist === false) { suggestTitle.push({value : value.title.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase())})
                  if ((suggestTitle.length - 1) !== countExist) { suggestTitle.pop() }
                }
              }
            }
            // Suggest Type
            if (value.type != null) {
              if ( suggestType.length <= 0) { suggestType.push({value : value.type.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase())}) }
              else {
                var isExist = true
                var countExist = 0
                suggestType.forEach((val, i) => {
                  if (value.type.toLowerCase().trim() !== val.value.toLowerCase().trim()) { isExist = false, countExist += 1 }
                  else { isExist = true }
                });
                if (isExist === false) { suggestType.push({value : value.type.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase())})
                  if ((suggestType.length - 1) !== countExist) { suggestType.pop() }
                }
              }
            }
            // Suggest Category
            if (value.category != null) {
              if ( suggestCategory.length <= 0) { suggestCategory.push({value : value.category.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase())}) }
              else {
                var isExist = true
                var countExist = 0
                suggestCategory.forEach((val, i) => {
                  if (value.category.toLowerCase().trim() !== val.value.toLowerCase().trim()) { isExist = false, countExist += 1 }
                  else { isExist = true }
                });
                if (isExist === false) { suggestCategory.push({value : value.category.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase())})
                  if ((suggestCategory.length - 1) !== countExist) { suggestCategory.pop() }
                }
              }
            }
            // Suggest Area
            if (value.area != null) {
              if ( suggestArea.length <= 0) { suggestArea.push({value : value.area.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase())}) }
              else {
                var isExist = true
                var countExist = 0
                suggestArea.forEach((val, i) => {
                  if (value.area.toLowerCase().trim() !== val.value.toLowerCase().trim()) { isExist = false, countExist += 1 }
                  else { isExist = true }
                });
                if (isExist === false) { suggestArea.push({value : value.area.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase())})
                  if ((suggestArea.length - 1) !== countExist) { suggestArea.pop() }
                }
              }
            }
          });
        })
        .finally(() => {
          // state.isFetching = false
        })
    }

    const formatMoment = (value) => {
      return value instanceof moment ? value.format('YYYY-MM-DD') : value
    }

    const handleOk = async () => {
      try {
        await formRef.value.validate()
      } catch (error) {
        return
      }

      form.code = String(form.code.toUpperCase())

      const form_data = serialize(
        {
          ..._.omit(form, [
            'valid_until',
            'start_redeem_date',
            'end_redeem_date',
            'is_deleted',
            'created_at',
            'updated_at',
            'created_by',
            'updated_by',
            'access_clients_created_by',
            'access_clients_updated_by',
          ]),
          valid_until: formatMoment(form.valid_until),
          start_redeem_date: formatMoment(form.start_redeem_date),
          end_redeem_date: formatMoment(form.end_redeem_date),
          image: image.value,
        },
        { indices: true },
      )

      const url = `/api/loyalty-rewards${form.id ? '/' + form.id + '?_method=PUT' : ''}`
      apiClient
        .post(url, form_data)
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          message.success('Berhasil menyimpan')
          emit('handleOk', form, true)
        })
        .catch(async error => {
          message.error('Gagal menyimpan')
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
    }

    const handleCancel = e => {
      emit('update:visible', false)
    }

    const onFileChange = async (e, type) => {
      var files = e.target.files || e.dataTransfer.files

      const allowed_extensions = new Array('jpg', 'png', 'gif', 'jpeg')
      const isExtOk = fileName => {
        const file_extension = fileName
          .split('.')
          .pop()
          .toLowerCase()
        return allowed_extensions.includes(file_extension)
      }

      if (!files.length) return
      // reset visual of file uploader because of html bug
      const resetVisualUploader = async () => {
        if ('image' === type) {
          image.value = true
        }
        // await nextTick()

        if ('image' === type) {
          image.value = null
          fileImageKey.value++
        }
      }

      // only 1 file upload allowed, lower than 2mb and some ext of image files
      // but not for document
      if (!isExtOk(files[0].name) && type !== 'document') {
        message.warning(`Hanya Boleh Gambar ${allowed_extensions}`)
        await resetVisualUploader()
        return
      }

      var filesize = (files[0].size / 1024 / 1024).toFixed(4) // MB
      if (filesize > 2.0) {
        message.warning('Ukuran harus di bawah 2MB!', 5.0)
        await resetVisualUploader()
        return
      }

      if ('image' === type) {
        image.value = files[0]
        imageToBase64(image.value, toRefs(fotos).url_image)
      }
    }

    watch(() => {
      if (form.redeem_point < 0) {
        isNegativePoint.value = true;
      } else {
        isNegativePoint.value = false;
      }

      if (form.max_qty < 0) {
        isNegativeMaxQty.value = true;
      } else {
        isNegativeMaxQty.value = false;
      }

      if (form.quota_store < 0) {
        isNegativeQuotaStore.value = true;
      } else {
        isNegativeQuotaStore.value = false;
      }
    })

    onMounted(() => {
      fetchData()
    })

    const imageToBase64 = (file, to) => {
      let heightImg = 180
      let widthImg = 320
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (evt) => {
        const img = new Image();
        img.onload = () => {
          if (img.width === widthImg && img.height === heightImg) {
            to.value = reader.result
          } else {
            message.warning('Rekomendasi dimensi gambar ' + widthImg + " x " + heightImg)
            image.value = null
            fileImageKey.value++
          }
        }
        img.src = evt.target.result;
      }
      reader.onerror = function(error) {
        console.log('Error: ', error)
      }
    }

    const filterOption = (input, option) => {
      return option.value.toUpperCase().indexOf(input.toUpperCase()) >= 0;
    }

    return {
      image,
      form,
      ...toRefs(form),
      data,
      suggestTitle,
      suggestType,
      suggestCategory,
      suggestArea,
      emit,
      rules,
      handleOk,
      handleCancel,
      formRef,
      errorMessage,
      onFileChange,
      ...toRefs(fotos),
      filterOption,
      isNegativeMaxQty,
      isNegativePoint,
      isNegativeQuotaStore,
      disabledStartDate,
      disabledEndDate,
      changeMasaBerlaku,
      isDateValidChange,
      changeStartDate,
      fileImageKey,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
